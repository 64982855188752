.block-article_preview {
    margin: 0 auto;
    max-width: var(--width-main);
    /*padding: 0 var(--block-space-horizontal);*/
    padding-left: var(--block-space-horizontal);
    padding-right: var(--block-space-horizontal);
}

.block-article_preview .article {
    border-bottom: 1px solid var(--color-dark);
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: 100%;
    min-width: 100%;
    padding-right: 2rem;
}

/* Remove border bottom for single column preview */
.block-article_preview .article:last-of-type {
    border-bottom: none;
}

.block-article_preview .spaceTopAndBottom {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.block-article_preview .spaceBottom {
    padding-top: 0;
    padding-bottom: 2rem;
}
.block-article_preview .spaceTop {
    padding-top: 2rem;
    padding-bottom: 0;
}

.block-article_preview .article .categories {
    display: none;
    color: var(--color-blue);
    padding-bottom: 0.5rem;
}

.block-article_preview .article .image {
    margin: 0;
}
.block-article_preview .article .image div {
    width: 100%;
    height: 410px;
    background-size: cover;
    background-position: center;
}

.block-article_preview .article .info {
    margin: 0;
}

.block-article_preview .article a p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.block-article_preview .article a {
    text-decoration: none;
}

.block-article_preview .article a:hover h3,
.block-article_preview .article h4 {
    color: var(--color-yellow);
}

.block-article_preview .article h3 {
    margin: 0 0 1rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.block-article_preview .article .text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.block-article_preview .article h4 {
    margin: 0 0 .5rem;
}

.block-article_preview .article .content {
    padding: initial;
    width: 100%;
    max-width: 100%;
    overflow-wrap: anywhere;
    height: 100%;
    /*min-height: 135px;*/
}

.block-article_preview .article .content p:last-child {
    margin-bottom: 0;
}
.block-article_preview .article .info, .block-article_preview .article .date{
    margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
    .block-article_preview .article .info, .block-article_preview .article .date{
        margin-bottom: 0.5rem;
    }

    .block-article_preview .article .info img {
        width: 100%;
    }
}

@media (min-width: 480px) {
    .block-article_preview .articles .image {
    }

    .block-article_preview .article .image div {
        width: 100%;
        height: 400px;
        background-size: cover;
        background-position: center;
    }
}
@media (min-width: 640px) {
    .block-article_preview .article {
        display: grid;
        gap: 2rem;
        grid-template-columns: calc(200px) 1fr;
    }
    .block-article_preview .article.no-image {
        display: grid !important;
        gap: 2rem !important;
        grid-template-columns: auto !important;
    }

    .block-article_preview .articles .image {
        max-width: 100%;
        max-height: 135px;
        height: 135px;
    }

    .block-article_preview .article .image div {
        width: 200px;
        max-width: 200px;
        height: 135px;
        background-size: cover;
        background-position: center;
    }
}
@media (min-width: 800px) {
    .block-article_preview .article {
        display: grid;
        gap: 2rem;
        grid-template-columns: calc(200px) 1fr;
    }
    .block-article_preview .article.no-image {
        display: grid !important;
        gap: 2rem !important;
        grid-template-columns: auto !important;
    }

    .block-article_preview .article .categories {
        display: none;
        color: var(--color-blue);
        padding-bottom: 0.5rem;
    }

    .block-article_preview .articles .image {
        max-width: 100%;
        max-height: 135px;
        height: 135px;
    }

    .block-article_preview .article .image {
        width: 100%;
        height: 135px;
        background-size: cover;
        background-position: center;
    }
}
@media (min-width: 1200px) {
    .block-article_preview .articles.multipleColumn {
        display: grid;
        gap: 0 2rem;
        grid-template-columns: 50% 50%;
    }

    .block-article_preview .multipleColumn .no-border {
        border-bottom: none;
    }

    /* Remove border bottom for multiple column preview */
    /*.block-article_preview .multipleColumn li:first-child:nth-last-child(2),*/
    /*.block-article_preview .multipleColumn li:first-child:nth-last-child(2) ~ li:nth-of-type(2) {*/
    /*    border-bottom: none;*/
    /*}*/

    /*.block-article_preview .multipleColumn li:first-child:nth-last-child(10) ~ li:nth-of-type(9),*/
    /*.block-article_preview .multipleColumn li:first-child:nth-last-child(8) ~ li:nth-of-type(7),*/
    /*.block-article_preview .multipleColumn li:first-child:nth-last-child(6) ~ li:nth-of-type(5),*/
    /*.block-article_preview .multipleColumn li:first-child:nth-last-child(4) ~ li:nth-of-type(3) {*/
    /*    border-bottom: none;*/
    /*}*/

    /*.block-article_preview .articles.multipleColumn .article:nth-of-type(2n + 1) {*/
    /*    border-right: 1px solid black;*/
    /*}*/

    .block-article_preview .article.no-image {
        display: grid !important;
        gap: 2rem !important;
        grid-template-columns: auto !important;
    }

    .block-article_preview .article {
        display: grid;
        gap: 2rem;
        grid-template-columns: 200px 1fr;
    }

    .block-article_preview .article .content {
        padding: initial;
        width: 100%;
        max-width: 100%;
        overflow-wrap: anywhere;
    }


    .block-article_preview .article .categories {
        display: none;
        color: var(--color-blue);
        padding-bottom: 0.5rem;
    }

    .block-article_preview .article .image {
        width: 100%;
        height: 135px;
        background-size: cover;
        background-position: center;
    }
}

